import { Link } from "gatsby";
import React from "react";
import Button from "../components/Button";

function NotFoundPage() {
  return (
    <div className="grid text-center my-32">
      <h2 className="font-bold my-8 p-3">Página não encontrada...</h2>
      <Link to="/">
        <Button className="my-10">Voltar para a página inicial</Button>
      </Link>
    </div>
  );
}

export default NotFoundPage;
